import React from 'react';

import Layout from '../components/Layout';
import MixItRecipes from '../components/MixItRecipes';

import '../styles/global.scss';

const RecipesPage = () => (
  <Layout view="mix-it-recipes">
    <div>
      <MixItRecipes />
    </div>
  </Layout>
);

export default RecipesPage;