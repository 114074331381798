import React from 'react';

import './MixItRecipes.scss';

const MixItRecipes = () => (
  <div className="recipes-container">
    <iframe src="https://selectsweep.trigger.games/recipes" title="Mix It Recipes" />
  </div>
);

export default MixItRecipes;
